import React from 'react';

import { AppBar, Box, IconButton, Toolbar } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import { useRouter } from 'next/router';

import ElevationScroll from 'components/@navigation/ElevationScroll';

interface Props {
  onBackClick?: () => void;
  rightItems?: JSX.Element;
  noBack?: boolean;
}

const HeaderSimple = ({ onBackClick, rightItems, noBack = false }: Props) => {
  const router = useRouter();

  const handleGoBack = () => {
    router.back();
  };

  return (
    <>
      <ElevationScroll>
        <AppBar color="default" elevation={0}>
          <Toolbar variant="dense">
            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
              {!noBack && (
                <Box color="text.primary">
                  <IconButton
                    edge="start"
                    aria-label="back"
                    color="inherit"
                    onClick={onBackClick ?? handleGoBack}
                  >
                    <BackIcon />
                  </IconButton>
                </Box>
              )}
              {rightItems !== undefined && (
                <Box display="flex" justifyContent="flex-end" width="100%">
                  {rightItems}
                </Box>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Box height={56} flexShrink={0} />
    </>
  );
};

export default HeaderSimple;

import React from 'react';

import { BoxProps } from '@material-ui/core';

import ExtraSmallLoadingContentCard from './variants/ExtraSmall';
import LargeLoadingContentCard from './variants/Large';
import MediumLoadingContentCard from './variants/Medium';
import SmallLoadingContentCard from './variants/Small';

export interface LoadingContentCardProps extends BoxProps {
  type: 'xs' | 'sm' | 'md' | 'lg';
}

const LoadingContentCard = ({ type, ...rest }: LoadingContentCardProps) => {
  switch (type) {
    case 'xs':
      return <ExtraSmallLoadingContentCard {...rest} />;
    case 'sm':
      return <SmallLoadingContentCard {...rest} />;
    case 'md':
      return <MediumLoadingContentCard {...rest} />;
    case 'lg':
      return <LargeLoadingContentCard {...rest} />;
  }
};

export default LoadingContentCard;

import React from 'react';

import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import Image from 'next/image';

import Link from 'components/@navigation/Link';

interface Props {
  title: string;
  image: string;
  blurImage?: string;
  href?: string;
  imagesAboveFold?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    height: '38px',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    width: '96px',
    textTransform: 'none',
    textAlign: 'left',
  },
  button: {
    padding: 0,
    height: '100%',
    margin: theme.spacing(0, 1),
  },
}));

const ContentCardExtraSmall: React.FC<Props> = ({
  title,
  image,
  blurImage,
  href,
  imagesAboveFold,
}) => {
  const classes = useStyles();

  return (
    <Button
      classes={{ label: classes.label, root: classes.button }}
      component={href ? Link : 'button'}
      href={href}
    >
      <Box borderRadius={12} width="100%" height={96} position="relative" overflow="hidden" mb={2}>
        <Image
          src={image}
          alt={`${title} image`}
          layout="fill"
          objectFit="cover"
          blurDataURL={blurImage}
          placeholder="blur"
          priority={imagesAboveFold}
        />
      </Box>
      <Typography classes={{ root: classes.root }} variant="subtitle2" component="h2">
        {title}
      </Typography>
    </Button>
  );
};

export default ContentCardExtraSmall;

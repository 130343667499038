import React from 'react';

import { ImageList, ImageListItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  imageListNoPadding: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  imageListItem: {
    width: 'auto !important',
    padding: '0 !important',
  },
  firstImage: {
    '& a': {
      marginLeft: theme.spacing(4),
    },
  },
  lastImage: {
    '& a': {
      marginRight: theme.spacing(4),
    },
  },
}));

interface Props {
  id: string;
  omitPadding?: boolean;
}

const HorizontalList: React.FC<Props> = ({ children, id, omitPadding = false }) => {
  const classes = useStyles();
  const items = React.Children.toArray(children);

  return (
    <ImageList
      className={omitPadding ? classes.imageListNoPadding : classes.imageList}
      rowHeight="auto"
      gap={8}
    >
      {items.map((child, key) => (
        <ImageListItem
          key={`${id}-${key}`}
          classes={{ root: classes.imageListItem }}
          className={
            key === 0
              ? classes.firstImage
              : key === items.length - 1
              ? classes.lastImage
              : undefined
          }
        >
          {typeof child === 'string' || typeof child === 'number'
            ? child
            : React.cloneElement(child as React.ReactElement<unknown>)}
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default HorizontalList;

import _isEmpty from 'lodash/isEmpty';
import _pickBy from 'lodash/pickBy';
import { useRouter } from 'next/router';
import { parse, ParsedQs, stringify } from 'qs';

export type QueryStringResult = [ParsedQs, (values: object) => void];

export default function useQueryString(): QueryStringResult {
  const { push, asPath } = useRouter();
  const parsedQuery = parse(asPath.split('?').pop() ?? '');

  const setQuery = (values: object) => {
    const newQuery = _pickBy({
      ...parsedQuery,
      ...values,
    });

    const path = _isEmpty(newQuery)
      ? asPath.split('?').shift() ?? ''
      : `${asPath.split('?').shift()}?${stringify(newQuery)}`;

    push(path, undefined, {
      shallow: true,
    });
  };

  return [parse(asPath.split('?').pop() ?? ''), setQuery];
}

import React from 'react';

import { Box, BoxProps, makeStyles, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 !important',
    maxWidth: 328,
    width: '100%',
    height: 257,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[50],
    filter: 'drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12))',
  },
  image: {
    height: 185,
    borderRadius: 4,
    backgroundColor: theme.palette.divider,
    width: '100%',
  },
  content: {
    height: 72,
    width: '100%',
    backgroundColor: theme.palette.primary.contrastText,
    padding: theme.spacing(4),
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  icon: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
  },
  text: {
    backgroundColor: theme.palette.grey[300],
  },
}));

const LargeLoadingContentCard: React.FC<BoxProps> = ({ ...props }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      {...props}
    >
      <Skeleton variant="rect" className={classes.image} />
      <Box display="flex" flexDirection="row" alignItems="center" className={classes.content}>
        <Skeleton variant="rect" className={classes.icon} />
        <Box display="flex" flexDirection="column" justifyContent="flex-start" width="auto">
          <Typography variant="subtitle2">
            <Skeleton variant="text" width={150} className={classes.text} />
          </Typography>
          <Typography variant="subtitle2">
            <Skeleton variant="text" width="60%" className={classes.text} />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LargeLoadingContentCard;

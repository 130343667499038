import React from 'react';

import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import Image from 'next/image';

import GameIcon from 'components/@common/GameIcon';
import Link from 'components/@navigation/Link';

// TODO:
// Inconsistent use of interface names. Have something like ContentCardBaseProps for every size.
export interface ContentCardMediumProps {
  title: string;
  subTitle?: string;
  bannerImage: string;
  image?: string;
  blurImage?: string;
  onClick?: () => void;
  href?: string;
  imagesAboveFold?: boolean;
}

const useStyles = makeStyles(theme => ({
  button: {
    padding: '0 !important',
    width: 201,
    height: 176,
    boxShadow: '0px 1px 18px rgba(0, 0, 0, 0.12)',
    margin: theme.spacing(4, 1, 4, 1),
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textTransform: 'none',
    height: '100%',
  },
}));

export const ContentCardInfo = ({
  title,
  subTitle,
  image,
  blurImage,
  imagesAboveFold,
}: Pick<
  ContentCardMediumProps,
  'title' | 'subTitle' | 'image' | 'blurImage' | 'imagesAboveFold'
>) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      width="100%"
      height="auto"
      p={2}
      bgcolor="background.paper"
      flex={1}
    >
      {image && (
        <GameIcon
          src={image}
          alt={`${subTitle} icon`}
          blurDataURL={blurImage}
          imagesAboveFold={imagesAboveFold}
        />
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        overflow="hidden"
      >
        <Box whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" textAlign="left" clone>
          <Typography variant="subtitle1" component="span">
            <b>{title}</b>
          </Typography>
        </Box>
        {subTitle && (
          <Box
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            textAlign="left"
            width="100%"
            clone
          >
            <Typography variant="subtitle2" color="textSecondary" component="span">
              {subTitle}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ContentCardMedium: React.FC<ContentCardMediumProps> = ({
  title,
  subTitle,
  bannerImage,
  image,
  blurImage,
  onClick,
  href,
  imagesAboveFold,
}) => {
  const classes = useStyles();

  return (
    <Button
      component={href ? Link : 'button'}
      href={href}
      classes={{ root: classes.button, label: classes.label }}
      onClick={href ? undefined : onClick}
      data-testid="game"
    >
      <Box width="100%" height={112} position="relative" overflow="hidden">
        <Image
          src={bannerImage}
          alt={`${title} image`}
          layout="fill"
          objectFit="cover"
          blurDataURL={blurImage}
          placeholder="blur"
          priority={imagesAboveFold}
        />
      </Box>
      <ContentCardInfo
        title={title}
        subTitle={subTitle}
        image={image}
        blurImage={blurImage}
        imagesAboveFold={imagesAboveFold}
      />
    </Button>
  );
};

export default ContentCardMedium;

import React from 'react';

import { Box, BoxProps, makeStyles, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  icon: {
    width: 96,
    height: 96,
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}));

const ExtraSmallLoadingContentCard: React.FC<BoxProps> = ({ ...props }) => {
  const classes = useStyles();

  return (
    <Box {...props} mx={1}>
      <Skeleton variant="rect" className={classes.icon} />
      <Typography variant="subtitle2">
        <Skeleton variant="text" width={96} height={36} />
      </Typography>
    </Box>
  );
};

export default ExtraSmallLoadingContentCard;

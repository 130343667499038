import React from 'react';

import { Box, BoxProps, makeStyles, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 !important',
    width: 200,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0px 1px 18px rgba(0, 0, 0, 0.12)',
  },
  image: {
    height: 112,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}));

const MediumLoadingContentCard: React.FC<BoxProps> = ({ ...props }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container} {...props}>
      <Skeleton variant="rect" className={classes.image} />
      <Box display="flex" flexDirection="row" width="100%">
        <Skeleton variant="rect" className={classes.icon} />
        <Box display="flex" flexDirection="column" justifyContent="flex-start" width="auto">
          <Typography variant="subtitle2">
            <Skeleton variant="text" width={150} />
          </Typography>
          <Typography variant="subtitle2">
            <Skeleton variant="text" width="60%" />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MediumLoadingContentCard;

import { GAME_PROVIDER_SHORTCODES } from 'constants/games';
import { Game, GameProvider, TypeOfGame } from 'services/api/definitions/game.definitions';

export const canPlayGame = ({ type, url }: Pick<Game, 'type' | 'url'>) => {
  // Do not show a play button when api decides to leave out the url...
  if (Object.values(TypeOfGame).includes(type)) {
    return true;
  }

  return Boolean(url);
};

export const isExternalGameProvider = (provider?: GameProvider) =>
  provider ? [GameProvider.Gameloft, GameProvider.Nazara].includes(provider) : false;

export const getGameProviderShortcode = (provider?: GameProvider) =>
  provider ? GAME_PROVIDER_SHORTCODES[provider] : undefined;

import React, { ChangeEventHandler } from 'react';

import { IconButton, InputAdornment, OutlinedTextFieldProps, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';
import { useIntl } from 'react-intl';

export interface Props extends Omit<OutlinedTextFieldProps, 'onChange' | 'value' | 'variant'> {
  variant?: 'outlined';
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClear: () => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(1),
  },
  input: {
    border: `1px solid ${theme.states.primary.outlinedHover}`,
    backgroundColor: theme.states.primary.outlinedHover,
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  adornedEnd: {
    paddingRight: theme.spacing(2),
  },
}));

const SearchInput: React.FC<Props> = ({
  value,
  onChange,
  onClear,
  variant = 'outlined',
  ...rest
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <TextField
      variant={variant}
      color="primary"
      className={classes.root}
      value={value}
      size="small"
      placeholder={intl.formatMessage({ id: 'input.search.placeholder' })}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search className={classes.icon} />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {!!value && (
              <InputAdornment position="end">
                <IconButton onClick={() => onClear()} hidden={true} size="small">
                  <Close className={classes.icon} />
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
        classes: {
          notchedOutline: classes.input,
          adornedEnd: classes.adornedEnd,
        },
      }}
      {...rest}
    />
  );
};

export default SearchInput;

import React from 'react';

import { Box, List } from '@material-ui/core';
import { useAnalytics, useAuth } from 'hooks';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { Routes } from 'routes';

import { canPlayGame, isExternalGameProvider } from 'utils/game.utils';
import { generatePath } from 'utils/routes.utils';

import blurBase64 from 'constants/blurBase64';
import { Events } from 'constants/events';
import { GameTeaser } from 'services/api/definitions/collections.definitions';
import { ContentType, SubscriptionType } from 'services/api/definitions/game.definitions';

import ContentCardExtraSmall from 'components/@common/ContentCard/ExtraSmall';
import ContentCard from 'components/@common/ContentCard/Medium';
import ContentCardSmall from 'components/@common/ContentCard/Small';
import HorizontalList from 'components/@common/HorizontalList';

export type SectionItem = Pick<
  GameTeaser,
  | 'id'
  | 'name'
  | 'collectionName'
  | 'smallTeaserImage'
  | 'mediumTeaserImage'
  | 'largeTeaserImage'
  | 'price'
  | 'subscriptionType'
  | 'slugName'
  | 'contentType'
  | 'provider'
  | 'type'
  | 'url'
>;

interface Props {
  data: SectionItem[];
  id: string;
  variant?: 'large' | 'small' | 'detail';
  imagesAboveFold?: boolean;
  disablePadding?: boolean;
  onClickItem?: (item: SectionItem) => void;
}

const GameSection: React.FC<Props> = ({
  data,
  variant = 'large',
  id,
  imagesAboveFold,
  disablePadding = false,
  onClickItem,
}) => {
  const intl = useIntl();
  const router = useRouter();
  const { isLoggedIn } = useAuth();
  const { send } = useAnalytics();

  if (variant === 'detail') {
    return (
      <Box mx={disablePadding ? 0 : 4}>
        <List>
          {data.map(game => {
            const gamePrice =
              game.price.toLowerCase() === 'free'
                ? intl.formatMessage({ id: 'game.details.price.free' })
                : game.price;
            return (
              <ContentCardSmall
                key={game.id}
                title={game.name}
                subTitle={game.collectionName}
                image={game.smallTeaserImage}
                buttonText={
                  isExternalGameProvider(game.provider)
                    ? intl.formatMessage({ id: 'game.action.view' })
                    : gamePrice
                }
                blurImage={blurBase64}
                explainerText={
                  game.subscriptionType === SubscriptionType.oneTimePurchase
                    ? intl.formatMessage({ id: 'game.explainer_text.one_time_pay' })
                    : intl.formatMessage({ id: 'game.explainer_text.per_game_session' }) // TODO: add all cases
                }
                onClick={() => onClickItem?.(game)}
                onActionClick={
                  game.contentType === ContentType.game && canPlayGame(game)
                    ? () => {
                        if (!isExternalGameProvider(game.provider)) {
                          send({
                            event: Events.gamePlayed,
                            data: { game: { slug: game?.slugName } },
                            withUser: true,
                          });
                        }
                        router.push(
                          generatePath(
                            isExternalGameProvider(game.provider)
                              ? Routes.gameDetails
                              : Routes.game,
                            { slug: game.slugName },
                          ),
                        );
                      }
                    : undefined
                }
                href={generatePath(Routes.gameDetails, { slug: game.slugName })}
                imagesAboveFold={imagesAboveFold}
                disableAction={!isLoggedIn}
              />
            );
          })}
        </List>
      </Box>
    );
  }

  if (variant === 'small') {
    return (
      <HorizontalList id={id} omitPadding={disablePadding}>
        {data.map(game => (
          <ContentCardExtraSmall
            key={game.id}
            title={game.name}
            image={game.smallTeaserImage}
            blurImage={blurBase64}
            href={generatePath(Routes.gameDetails, { slug: game.slugName })}
            imagesAboveFold={imagesAboveFold}
          />
        ))}
      </HorizontalList>
    );
  }

  return (
    <HorizontalList id={id} omitPadding={disablePadding}>
      {data.map((game, index) => (
        <ContentCard
          key={game.id}
          title={game.name}
          subTitle={game.collectionName}
          bannerImage={game.largeTeaserImage}
          image={game.smallTeaserImage}
          blurImage={blurBase64}
          href={generatePath(Routes.gameDetails, { slug: game.slugName })}
          imagesAboveFold={imagesAboveFold && index < 3}
        />
      ))}
    </HorizontalList>
  );
};

export default GameSection;

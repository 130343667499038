import React from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import LoadingContentCard from 'components/@common/ContentCard/Loading/LoadingContentCard';

interface Props {
  value: string;
  onClear: () => void;
}

const SearchEmptyState: React.FC<Props> = ({ value, onClear }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <LoadingContentCard type="sm" mb={5} bgcolor="grey.300" />
      <LoadingContentCard type="sm" mb={6} bgcolor="grey.300" />
      <Typography variant="h5" component="h1">
        <FormattedMessage id="discover.search.empty.title" />
      </Typography>
      <Box mt={2} mb={4}>
        <Typography variant="body1" color="textSecondary">
          <FormattedMessage id="discover.search.empty.description" values={{ value }} />
        </Typography>
      </Box>
      <Button onClick={onClear} variant="outlined" fullWidth color="primary" size="medium">
        <FormattedMessage id="discover.search.empty.button" />
      </Button>
    </Box>
  );
};

export default SearchEmptyState;

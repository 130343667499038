import React from 'react';

import dynamic from 'next/dynamic';

import { Game, GameProvider, TypeOfGame } from 'services/api/definitions/game.definitions';

const ExternalGame = dynamic(() => import('components/@game/ExternalGame'));
const Raffle = dynamic(() => import('components/@game/InternalGame/Raffle'));
const ScratchAndWin = dynamic(() => import('components/@game/InternalGame/ScratchAndWin'));
const Trivia = dynamic(() => import('components/@game/InternalGame/Trivia'));
const Voting = dynamic(() => import('components/@game/InternalGame/Voting'));
const SlotMachine = dynamic(() => import('components/@game/InternalGame/SlotMachine'));

// TODO: enable when games are available

// const SpinTheWheel = dynamic(() => import('components/@game/InternalGame/SpinTheWheel'));

export const Games = (game: Game) => {
  switch (game.type) {
    case TypeOfGame.scratch:
      return <ScratchAndWin game={game} />;
    case TypeOfGame.quiz:
      return <Trivia id={game.id} slug={game.slugName} />;
    case TypeOfGame.vote:
      return <Voting id={game.id} slug={game.slugName} />;
    case TypeOfGame.raffle:
      return <Raffle id={game.id} slug={game.slugName} />;
    case TypeOfGame.slot:
      return <SlotMachine id={game.id} slug={game.slugName} />;
    // case TypeOfGame.wheel:
    //   return <SpinTheWheel id={game.id} slug={game.slugName} />;
    case TypeOfGame.webview:
      return <ExternalGame game={game} />;
    default:
      return null;
  }
};

export const GAME_PROVIDER_SHORTCODES = {
  [GameProvider.Newcom]: '', // TODO: we need the code
  [GameProvider.Gameloft]: '18764442404',
  [GameProvider.Nazara]: '18764442484',
};

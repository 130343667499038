import React from 'react';

import { Box, BoxProps, makeStyles, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  icon: {
    width: 64,
    height: 64,
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
  },
}));

const SmallLoadingContentCard: React.FC<BoxProps> = ({ bgcolor, ...props }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="row" {...props}>
      <Skeleton variant="rect" className={classes.icon} style={{ backgroundColor: bgcolor }} />
      <Box display="flex" flexDirection="column" justifyContent="center" width="auto">
        <Typography variant="subtitle2">
          <Skeleton variant="text" width={150} style={{ backgroundColor: bgcolor }} />
        </Typography>
        <Typography variant="subtitle2">
          <Skeleton variant="text" width="60%" style={{ backgroundColor: bgcolor }} />
        </Typography>
      </Box>
    </Box>
  );
};

export default SmallLoadingContentCard;

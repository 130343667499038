import React from 'react';

import {
  Box,
  Button,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Image from 'next/image';

import Link from 'components/@navigation/Link';

const useStyles = makeStyles(theme => ({
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: theme.spacing(2),
    paddingRight: 120,
  },
  listItemSecondaryAction: {
    right: 0,
  },
}));
interface Props {
  title: string;
  subTitle: string;
  image: string;
  buttonText: string;
  explainerText?: string;
  blurImage?: string;
  href: string;
  disableAction?: boolean;
  onActionClick?: () => void;
  imagesAboveFold?: boolean;
  onClick?: () => void;
}

const ContentCardSmall: React.FC<Props> = ({
  title,
  subTitle,
  image,
  buttonText,
  explainerText,
  blurImage,
  href,
  imagesAboveFold,
  disableAction,
  onActionClick,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <ListItem
      className={classes.listItem}
      button
      component={Link}
      href={href}
      data-testid="game"
      disableGutters
      onClick={onClick}
    >
      <ListItemIcon>
        <Box
          borderRadius={12}
          width={64}
          height={64}
          position="relative"
          overflow="hidden"
          mr={3}
          flexShrink={0}
        >
          <Image
            src={image}
            alt={`${title} image`}
            layout="fill"
            objectFit="cover"
            blurDataURL={blurImage}
            placeholder="blur"
            priority={imagesAboveFold}
          />
        </Box>
      </ListItemIcon>
      <ListItemText>
        <Box whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" width="100%" clone>
          <Typography variant="subtitle1" component="p">
            {title}
          </Typography>
        </Box>
        {subTitle && (
          <Typography variant="subtitle2" component="span" color="textSecondary">
            {subTitle}
          </Typography>
        )}
      </ListItemText>
      {onActionClick && (
        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-end"
            ml={2}
            flexShrink={0}
            flexGrow={1}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={onActionClick}
              size="small"
              disabled={disableAction}
            >
              {buttonText}
            </Button>
            {explainerText && (
              <Typography variant="caption" color="textSecondary">
                {explainerText}
              </Typography>
            )}
          </Box>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default ContentCardSmall;

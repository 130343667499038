import { useCallback, useMemo, useState } from 'react';

import { useQueryString } from 'hooks';
import _debounce from 'lodash/debounce';

import { urlParams } from 'constants/urlParams';

export const useSimpleSearch = () => {
  const [query, setQuery] = useQueryString();
  const initialValue = query[urlParams.search] ? String(query[urlParams.search]) : '';
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  const searchDebounce = useMemo(
    () => _debounce((value: string) => setDebouncedValue(value), 500),
    [],
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setCurrentValue(e.target.value);
      searchDebounce(e.target.value);
      setQuery({ ...query, search: e.target.value });
    },
    [query, searchDebounce, setQuery],
  );

  const handleSearchClear = useCallback(() => {
    setCurrentValue('');
    setDebouncedValue('');
    setQuery({ ...query, search: undefined });
  }, [query, setQuery]);

  return {
    currentValue,
    debouncedValue,
    searchClear: handleSearchClear,
    searchChange: handleSearchChange,
  };
};
